<template>
	<!-- 音频会议 -->
  <div class="rtc-container">
    <!-- 进房操作区域 -->
    <div class="control-container" v-show="!isRoom" >
      <!-- <div class="rtc-control-container">
        <button
          class="button"
          type="primary"
          size="small" :disabled="isJoining || isJoined" @click="isToHome">连入房间</button>
      </div> -->
	  <p style="margin-top: 40vh;text-align: center;color: #9DA7B1;">请稍等....</p>
    </div>
	
	<!-- 进入房间 -->
	<div class="roomView"  v-show="isRoom"  >
	<!-- 远端流区域+本地 -->
	<div class="remote-container">
		<!-- 本地 -->
		<div class="yy-view" >
			<div id="localStream" style="display:none;width:0;height:0" ></div>
			<div class="zhezhao">
				<img :src="getHeadIcon(userId)" />
				<div class="yy-elseInfo" >
					<div class="userName" >{{getName(userId)}}</div>
					<img class="shengyin" v-show="audioVolumeObj[userId]>8" src="../../../assets/img/shipinIcon/shengyin.png" />
				</div>
				
			</div>
		</div>
	  <div
	    v-for="(item) in remoteStreamList"
	    :key="item.getUserId()"
		 class="yy-view"
	   >
	   <div style="display:none" :id="item.getUserId()"></div>
	   <div class="zhezhao">
		   <img :src="getHeadIcon(item.getUserId())" />
		   <div class="yy-elseInfo" >
			   <div class="userName" >{{getName(item.getUserId())}}</div>
			   <img class="shengyin" v-show="audioVolumeObj[item.getUserId()]>8" src="../../../assets/img/shipinIcon/shengyin.png" />
		   </div>
	   </div>
		<!-- <van-progress pivot-text="" :percentage="audioVolumeObj[item.getUserId()]" /> -->
	  </div>
	  <!-- audioVolumeObj -->
	</div>
	<div class="bottomView">
		<template v-if="isPlayingLocalStream">
			<div @click="changeIsMutedAudio">
				<img v-if="!isMutedAudio" src="../../../assets/img/icon/mkfkq.png" />
				<img v-else-if="isMutedAudio" src="../../../assets/img/icon/mkfgb.png" />
			</div>
			<!-- 退出视频 -->
			<div  @click="handleLeave">
				<img src="../../../assets/img/icon/guaduan.png" />
			</div>
		</template>
	</div>
	</div>
  </div>
</template>

<script>
import rtc from '../../../mixins/rtc.js';
import shareRtc from  '../../../mixins/share-rtc.js';
let jiashuImg = require("../../../assets/img/shipinIcon/jiashu.png");
const yizhuImg = require("../../../assets/img/shipinIcon/yizhu.png");
const yishengImg = require("../../../assets/img/shipinIcon/yisheng.png");
const zhuanjiaImg = require("../../../assets/img/shipinIcon/zhuanjia.png");
const huanzheImg = require("../../../assets/img/shipinIcon/huanzhe.png");
// import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import { Button,Progress } from "vant"
export default {
  name: 'audioConferencing',
  mixins: [rtc, shareRtc],
  components:{
	  [Button.name]:Button,
	  [Progress.name]:Progress,
  },
  inject: ["sendVARoomState"],
  props: {
    type: String,
     userObj:Object,
    secretKey: String,
    myUserId: String,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
	activeUser:Object,
  },
  data() {
    return {
      logList: [],
      inviteLink: '',
	  sdkAppId:"",
      showCopiedTip: false,
	  isRoom:false,
	  audioCallMode:true,
	  userId:"",
	  userSig:"",
	  ds:null,
    };
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
  },
  created() {
	  console.error("打开音频聊天室")
	  this.isToHome()
  },
  methods: {
	  changeIsMutedAudio(){
	  		  if(this.isMutedAudio){
	  			 this.unmuteAudio() 
	  		  }else{
	  			this.muteAudio()  
	  		  }
	  	  },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      
    },
	// 进入房间前的请求 判断有无进入权限
	 async isToHome(){
		 let myUserId="";
		 // 医助 A /患者 B /医生 C / 家属 D
		 myUserId+="B"
		 // 
		let f={};
		let getUserSigUrl="userSigInVoiceRoomId";
		f.roomID=this.activeUser.roomId;
		myUserId+=this.myUserId;
		this.userId+=myUserId;
		f.userId=this.userId;
		console.error(this.myUserId)
		this.$http.request(getUserSigUrl,f)
		 .then((res)=>{
		 			 console.log(res)
		 			 this.userSig=res.useSig;
		 			 this.roomId=Number(res.roomNum);
					 this.sdkAppId=res.sdkAppId
					 this.sendrState()
		 			 this.handleJoinRoom()
		 })
		 .catch((e)=>{
			 this.handleLeave()
		 			 console.log(e)
		 })
	 //    window.setTimeout(()=>{
  //       const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
  //       this.userSig = userSigGenerator.genTestUserSig(this.userId);
		// this.handleJoinRoom()
  //     },2000)	
	},
    // 点击【Join Room】按钮
    async handleJoinRoom() {
	  
	  console.warn("userSig="+this.userSig)
      // 初始化客户端
	  await this.initClient();
	  // 进入房间
      await this.join();
	  this.isRoom=true;
	  // （初始化？）客户端对象
      await this.initLocalStream();
	  // 呼叫偶去本地音视频 （渲染？）//渲染自己的
      await this.playLocalStream();
	  // 发布流？
      await this.publish();
    },

    // 点击【Publish】按钮
    async handlePublish() {
      await this.publish();
    },

    // 点击【Unpublish】按钮
    async handleUnpublish() {
      await this.unPublish();
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
		if (this.ds) {
		  window.clearInterval(this.ds);
		}
		if(this.sendVARoomState){
			this.sendVARoomState(3);
		}
      await this.leave();
	  this.$emit('leave')
    },

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.secretKey) {
        alert(this.$t('Please enter sdkAppId and secretKey'));
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
      
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      await this.handleShareUnpublish();
      await this.handleShareLeave();
    },

    // 显示成功的 Log
    addSuccessLog(log) {
		return;
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'success',
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },

    // 显示失败的 Log
    addFailedLog(log) {
		return;
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'failed',
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {},
    reportFailedEvent(name, error, type = 'rtc') {},
	$t(t){
		return t
	},
	// 创建定时 发送 10s 发送ws 视频状态处于连接中
	sendrState(){
		// 发送 患者接收视频通话的请求
		if (this.sendVARoomState) {
			this.sendVARoomState(1);
			if(this.ds){
				window.clearInterval(this.ds);
			}
			this.ds = window.setInterval(() => {
				this.sendVARoomState(1);
			}, 1000*10);
			
		}
	},
	// 获取名称
	getName(zid){
		let typeName="";
		if(zid.indexOf("A")==0){
			zid = zid.replace(/^A/, "Z_1_");
			typeName= "医助"
		}else if(zid.indexOf("B")==0){
			zid = zid.replace(/^B/, "Z_2_");
			typeName= "患者"
		}else if(zid.indexOf("C")==0){
			zid = zid.replace(/^C/, "Z_3_");
			typeName=this.userObj[zid].doctorType != 1?'医生':'专家'
		}else if(zid.indexOf("D")==0){
			return "家属"
		}
		try{
			if(this.userObj[zid]){
				return typeName+"："+this.userObj[zid].memberName
			}else{
				return '--'
			}
		}catch(e){
			return '--'
			console.error(e)
		}
	},
	// 获取头像
	getHeadIcon(zid) {
		try{
		if(zid.indexOf("A")==0){
			zid = zid.replace(/^A/, "Z_1_");
			return  yizhuImg; //this.userObj[zid].headImg ||
		}else if(zid.indexOf("B")==0){
			zid = zid.replace(/^B/, "Z_2_");
			return huanzheImg; // this.userObj[zid].headImg
		}else if(zid.indexOf("C")==0){
			zid = zid.replace(/^C/, "Z_3_");
			return (this.userObj[zid].doctorType != 1 ? yishengImg : zhuanjiaImg) //this.userObj[zid].headImg ||
		}else if(zid.indexOf("D")==0){
			return jiashuImg
		}
	}
	catch(e){
		console.error(e);
		return ""
	}
	},
  },
  beforeUnmount() {
    if (this.ds) {
      window.clearInterval(this.ds);
    }
  },
};
</script>

<style lang="scss" scoped>
.rtc-container {
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    text-align: left;
    margin-bottom: 10px;
    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }
    .button:not(:first-child) {
      margin-left: 2px;
    }
  }

}
.roomView{
	height: 100vh;
	width: 100%;
	overflow: hidden;
	background-color: #212126;
	.remote-container{
		height: calc(100% - 100px);
		overflow: auto;
		width: 7.21rem;
		margin:10px auto 0;
		// display: flex;
		// flex-wrap: wrap;
			.yy-view{
				float:left;
				width: 2.3rem;
				min-height: 2.3rem;
				margin: 0.05rem;
				position: relative;
				// background:#555;
				color:#fff;
				padding:0.05rem;
				 box-sizing: border-box;
				.zhezhao{
					width: 2.2rem;
					height: 2.2rem;
					position: relative;
					img{
						width:100%;
						height:100%;
						object-fit: contain;
					}
					.yy-elseInfo{
						position: absolute;
						width: 100%;
						box-sizing: border-box;
						padding: 3px;
						// background: red;
						bottom: 0;
						left: 0;
						display: flex;
						justify-content: space-between;
						.userName{
							overflow: hidden;
							white-space: nowrap;
							text-overflow:ellipsis;
							font-size:13px;
							 text-align: center;
							 padding:5px;
							 background-color: rgba(0, 0, 0, 0.5);
							 border-radius: 6px;
						}
						.shengyin{
							height: 20px;
							width: 20px;
						}
					}
					
				}
			} 
		
	}
	.bottomView{
		height: 100px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-color: #36393c;
		border-radius: 6px 6px 0 0;
		>div{
			background-color: #fff;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			img{
				width: 30px;
				height: 30px;
				margin: 15px;
			}
		}
	}
}

</style>


