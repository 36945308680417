<template>
	<!-- 视频会议 -->
  <div class="rtc-container">
    <!-- 进房操作区域 -->
    <div class="control-container" v-show="!isRoom" >
      <!-- <div class="rtc-control-container">
        <button
          class="button"
          type="primary"
          size="small" :disabled="isJoining || isJoined" @click="isToHome">连入房间</button>
      </div> -->
	  <p style="margin-top: 40vh;text-align: center;color: #9DA7B1;">请稍等....</p>
    </div>
	
	<!-- 进入房间 -->
	<div class="roomView"  v-show="isRoom"  >
    <!-- 远端流区域+本地 -->
    <div class="remote-container">
		<!-- 本地 -->
		<div  class="local-sp-view">
			<div class="local-stream-content voiceView" id="localStream">
				<div class="elsespInfo" >
					<div class="userName" >{{getName(userId)}}</div>
					<img class="shengyin" v-show="audioVolumeObj[userId]>8" src="../../../assets/img/shipinIcon/shengyin.png" />
				</div>
			</div>
		</div>
		
      <div
        v-for="(item) in remoteStreamList"
        :key="item.getUserId()"
       class="remote-stream-container">
		<div class="voiceView"  :id="item.getUserId()" >
			<div  class="elsespInfo" >
				<div class="userName" >{{getName(item.getUserId())}}</div>
				<img class="shengyin" v-show="audioVolumeObj[item.getUserId()]>8" src="../../../assets/img/shipinIcon/shengyin.png" />
			</div>
		</div>
      </div>
    </div>
	<div class="bottomView">
		<template v-if="isPlayingLocalStream">
			<div v-if="!isMutedVideo"  @click="muteVideo">
				<img  src="../../../assets/img/icon/sxtkq.png" />
			</div>
			<div v-if="isMutedVideo"  @click="unmuteVideo">
				<img  src="../../../assets/img/icon/sxtgb.png" />
			</div>
			<div v-if="!isMutedAudio" @click="muteAudio">
				<img  src="../../../assets/img/icon/mkfkq.png" />
			</div>
			<div v-if="isMutedAudio" @click="unmuteAudio">
				<img src="../../../assets/img/icon/mkfgb.png" />
			</div>
			<!-- 退出视频 -->
			<div  @click="handleLeave">
				<img src="../../../assets/img/icon/guaduan.png" />
			</div>
			<div @click="switchCamera">
				<img src="../../../assets/img/icon/qiehuan.png" >
			</div>
		</template>
	</div>
	</div>
   
  </div>
</template>

<script>
import rtc from '../../../mixins/rtc.js';
import shareRtc from  '../../../mixins/share-rtc.js';
import {Button,Progress} from "vant"
// import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';

export default {
  name: 'compRoom',
  mixins: [rtc, shareRtc],
  components:{
	  [Button.name]:Button,
	  [Progress.name]:Progress,
  },
  inject: ["sendVARoomState"],
  props: {
    type: String,
    secretKey: String,
    myUserId: String,
	userObj:Object,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
	activeUser:Object,
  },
  data() {
    return {
      logList: [],
      inviteLink: '',
	  sdkAppId:"",
      showCopiedTip: false,
	  isRoom:false,
	  userSig:"",
	  userId:"",
	  ds:null,
    };
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
  },
  created() {
	  this.isToHome()
  },
  methods: {
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      
    },
	// 进入房间前的请求 判断有无进入权限
	 async isToHome(){
		 
		 let myUserId="";
		 // 医助 A /患者 B /医生 C / 家属 D
		 myUserId+="B"
		 // 
		let f={};
		let getUserSigUrl="";
		f.roomID=this.activeUser.roomId;
		getUserSigUrl="userSigInVideoRoomId"
		myUserId+=this.myUserId;
		this.userId=myUserId;
		f.userId=this.userId;
		this.$http.request(getUserSigUrl,f)
		 .then((res)=>{
			 console.log(res)
			 this.userSig=res.useSig;
			 this.roomId=Number(res.roomNum);
			 this.sdkAppId=res.sdkAppId
			 this.sendrState()
			 this.handleJoinRoom()
		 })
		 .catch((e)=>{
			 this.handleLeave()
			 console.log(e)
		 })
		 
	 //    window.setTimeout(()=>{
  //       const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
  //       this.userSig = userSigGenerator.genTestUserSig(this.userId);
		// this.handleJoinRoom()
  //     },2000)	
	},
    // 点击【Join Room】按钮
    async handleJoinRoom() {
	  
	  console.warn("userSig="+this.userSig)
      // 初始化客户端
	  await this.initClient();
	  // 进入房间
      await this.join();
	  this.isRoom=true;
	  // （初始化？）客户端对象
      await this.initLocalStream();
	  // 呼叫偶去本地音视频 （渲染？）//渲染自己的
      await this.playLocalStream();
	  // 发布流？
      await this.publish();
    },

    // 点击【Publish】按钮
    async handlePublish() {
      await this.publish();
    },

    // 点击【Unpublish】按钮
    async handleUnpublish() {
      await this.unPublish();
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
		if (this.ds) {
		  window.clearInterval(this.ds);
		}
		if(this.sendVARoomState){
			this.sendVARoomState(4);
		}
      await this.leave();
	  this.$emit('leave')
    },

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.secretKey) {
        alert(this.$t('Please enter sdkAppId and secretKey'));
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
      
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      await this.handleShareUnpublish();
      await this.handleShareLeave();
    },

    // 显示成功的 Log
    addSuccessLog(log) {
		return;
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'success',
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },

    // 显示失败的 Log
    addFailedLog(log) {
		return;
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'failed',
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {},
    reportFailedEvent(name, error, type = 'rtc') {},
	$t(t){
		return t
	},
	// 创建定时 发送 10s 发送ws 视频状态处于连接中
	sendrState(){
		// 发送 患者接收视频通话的请求
		if (this.sendVARoomState) {
			this.sendVARoomState(2);
			if(this.ds){
				window.clearInterval(this.ds);
			}
			this.ds = window.setInterval(() => {
				this.sendVARoomState(2);
			}, 1000*10);
			
		}
	},
	// 获取名称
	getName(zid){
		let typeName="";
		if(zid.indexOf("A")==0){
			zid = zid.replace(/^A/, "Z_1_");
			typeName= "医助"
		}else if(zid.indexOf("B")==0){
			zid = zid.replace(/^B/, "Z_2_");
			typeName= "患者"
		}else if(zid.indexOf("C")==0){
			zid = zid.replace(/^C/, "Z_3_");
			typeName=this.userObj[zid].doctorType != 1?'医生':'专家'
		}else if(zid.indexOf("D")==0){
			return "家属"
		}
		try{
			if(this.userObj[zid]){
				return typeName+"："+this.userObj[zid].memberName
			}else{
				return '--'
			}
		}catch(e){
			return '--'
			console.error(e)
		}
	},
  },
  beforeUnmount() {
    if (this.ds) {
      window.clearInterval(this.ds);
    }
  },
};
</script>

<style lang="scss" scoped>
	@import url("../../../assets/rtc/global.css");
.rtc-container {
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    text-align: left;
    margin-bottom: 10px;
    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }
    .button:not(:first-child) {
      margin-left: 2px;
    }
  }

  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;
    .invite-input {
      margin-top: 10px;
    }
    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }
  .remote-container {
    margin-top: 10px;
	height: calc(100% - 110px);
	overflow: auto;
	width: 7.21rem;
	margin:10px auto 0;
	
	.local-sp-view{
		float:left;
		width: 2.3rem;
		min-height: 2.3rem;
		margin:0.05rem;
	}
    .remote-stream-container {
		float:left;
		width: 2.3rem;
		min-height: 2.3rem;
		margin:0.05rem;
	}
  }
}
.roomView{
	height: 100vh;
	width: 100%;
	overflow: hidden;
	background-color: #212126;
	color:#fff;
	.remote-container{
		height: calc(100% - 100px);
		overflow: auto;
	}
	.bottomView{
		height: 100px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-color: #36393c;
		border-radius: 6px 6px 0 0;
		>div{
			background-color: #fff;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			img{
				width: 30px;
				height: 30px;
				margin: 15px;
			}
		}
	}
	.voiceView{
		width: 2.3rem;
		height: 2.3rem;
		position: relative;
		.elsespInfo{
						position: absolute;
						width: 100%;
						box-sizing: border-box;
						padding: 3px;
						// background: red;
						bottom: 0;
						left: 0;
						display: flex;
						justify-content: space-between;
						.userName{
							z-index: 100;
							overflow: hidden;
							white-space: nowrap;
							text-overflow:ellipsis;
							font-size:13px;
							 
							 padding:5px;
							 background-color: rgba(0, 0, 0, 0.5);
							 border-radius: 6px;
						}
						.shengyin{
							z-index: 101;
							height: 20px;
							width: 20px;
						}
					}
	}
}
</style>


