<template>
	<div class="page-wrapper">
		<span @click="showPopup"><slot></slot></span>
		<van-popup v-model:show="isLoading.record" :close-on-click-overlay="false" get-container="body" position="bottom">
			<div style="display: flex;justify-content: space-between;line-height: 32px;color: #00a8ff;padding: 6px 16px;font-size: 16px;align-items: center;">
				<span @click="recClose">取消</span>
				<img class="qhIcon"  v-show="!Imgsrc"  @click="changeOpenMedia" src="../../assets/img/icon/qiehuan.png" />
				<span @click="upTrans">确定</span>
			</div>
			<div class="transcribe-view">
				<div>
					<video  style="height: 300px;width: 100%;" autoplay v-show="isShow.srcVideo" ref="srcVideo"
					:muted="true" webkit-playsinline playsinline x5-video-player-type="h5-page"
					></video>
				</div>
				<canvas ref="canvas" width="300" height="300" v-show="false" ></canvas>
				<div>
					<img v-if="Imgsrc" :src="Imgsrc" class="canvasImg" />
				</div>
				<div class="buttons">
					<van-button round block type="primary" v-show="!Imgsrc" @click="takePhoto" >拍摄</van-button>
					<van-button round block type="danger"  v-show="Imgsrc" @click="openMedia" >重新拍摄</van-button>
				</div>
			</div>
		</van-popup>
	</div>
</template>
<script>
import { Popup, Toast ,Button,Notify} from 'vant';
export default {
	name: 'photograph',
	data() {
		return {
			isLoading:{
				record:false,
			},
			isShow:{
				srcVideo:false,
			},
			mediaStream:"",
			Imgsrc:"", //图片地址
			swap:"user",
		};
	},
	components: {
		[Popup.name]: Popup,
		[Button.name]:Button,
	},
	// 激活
	activated() {
		console.log('激活2');
	},
	deactivated() {
		console.log('失活2');
	},
	created() {},
	methods: {
		// 弹出 弹层
		showPopup(){
			this.isLoading.record=true;
			this.swap="user"
			this.$nextTick(()=>{
				this.openMedia();
			})
		},
		// 切换摄像头
		changeOpenMedia(){
			// 关闭摄像头
			this.closeMedia();
			this.swap= this.swap=="user"?"environment":"user"
			// 开启摄像头
			this.openMedia();
		},
		// 开启摄像头
		     openMedia  () {
				 let _this=this;
		        this.Imgsrc='';
				let srcVideo=this.$refs.srcVideo;
				this.isShow.srcVideo=true;
		        let constraints = {
		            audio: false, //音频轨道
		            video: {
						width:300,
						height:300,
						facingMode:this.swap,
						}  //视频轨道
		        }
				 if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
						 this.getUserMedia(constraints); // 调用用户媒体设备，访问摄像头、录音、
					 } else {
						  alert("你的浏览器不支持访问用户媒体设备");
				 }
				
				// return;
		  //       let mediaPromise = navigator.mediaDevices.getUserMedia(constraints);
		  //       mediaPromise.then(function (stream) {}).catch(function (err) {});
		    },
		/* +++++++++++++ */
		    getUserMedia(constrains) {
		      let that = this;
		      if (navigator.mediaDevices.getUserMedia) {
		        // 最新标准API、
		        navigator.mediaDevices.getUserMedia(constrains)
				.then(stream => { that.success(stream); })
				.catch(err => { that.error(err); });
		      } else if (navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
		        // webkit内核浏览器
		        if (navigator.mediaDevices === undefined) {
		          navigator.mediaDevices = {};
		        }
		
		        // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
		        // 因为这样可能会覆盖已有的属性。这里我们只会在没有getUserMedia属性的时候添加它。
		        if (navigator.mediaDevices.getUserMedia === undefined) {
		          navigator.mediaDevices.getUserMedia = function(constraints) {
		            // 首先，如果有getUserMedia的话，就获得它
		            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
		
		            // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
		            if (!getUserMedia) {
		              return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
		            }
		
		            // 否则，为老的navigator.getUserMedia方法包裹一个Promise
		            return new Promise(function(resolve, reject) {
		              getUserMedia.call(navigator, constraints, resolve, reject);
		            });
		          }
		        }
		        navigator.mediaDevices.getUserMedia(constrains).then(stream => {
		          that.success(stream);
		        }).catch(err => {
		          that.error(err);
		        });
		      } else if (navigator.getUserMedia) {
		        // 旧版API
		        navigator.getUserMedia(constrains).then(stream => { that.success(stream); }).catch(err => { that.error(err); });
		      }
		    },
		    // 成功的回调函数
		    success(stream) {
		      console.log("已点击允许,开启成功");
			  let srcVideo=this.$refs.srcVideo;
			  this.mediaStream=stream;
			  srcVideo.srcObject = stream;
			  srcVideo.play();
		    },
		    // 异常的回调函数
		    error(error) {
		      console.log("访问用户媒体设备失败：", error.name, error.message);
		    },
		
		
		
		/* ------------- */
		    // 拍照
		     takePhoto(){
				 let srcVideo=this.$refs.srcVideo;
		        let canvas =this.$refs.canvas;
				console.log(srcVideo,canvas)
		        //获取 `canvas`元素，根据`srcVideo`中的数据进行图片绘制 `ctx.drawImage()`；
		        let ctx = canvas.getContext('2d');
		        ctx.drawImage(srcVideo, 0, 0, 300, 300);
		        //将 `canvas`绘制的图片信息，展示在 `img`标签中；
		        this.Imgsrc=canvas.toDataURL();
		        this.closeMedia();
				this.isShow.srcVideo=false;
		    },
		
		    // 关闭摄像头
		     closeMedia(){
				 if(!this.mediaStream){
					 return;
				 }
		        this.mediaStream.getTracks().forEach(track => {
		            track.stop();
		        });
		        // srcVideo.style.display='none';
		    },
			
			 base64ToFile(base64Data, fileName) {
		    let arr = base64Data.split(','),
		      fileType = arr[0].match(/:(.*?);/)[1],
		      bstr = atob(arr[1]),
		      l = bstr.length,
		      u8Arr = new Uint8Array(l);
		
		    while (l--) {
		      u8Arr[l] = bstr.charCodeAt(l);
		    }
			console.log(fileType,arr)
		    return new File([u8Arr], fileName+'.'+fileType.split('/')[1], {type: fileType});
		  },
		  // 取消按钮 关闭弹框
		  recClose() {
		  	this.isLoading.record = false;
		  	// 关闭摄像头
			this.closeMedia()
		  },
		  // 确认按钮 --回调给父组件数据 或者请求返还给父组件上传后的连接
		  upTrans(){
			  
			  // // 方式1
			  // // base64转blob
			  // const blob = base64ToBlob(this.Imgsrc);
			  // // blob转file
			  // const file1 = blobToFile(blob, '文件名');
			  // 方式2
			  // base64转file
			  if(!this.Imgsrc){
				  Notify({ type: 'warning', message: '请先进行拍摄' });
				  return;
			  }
			  const file2 = this.base64ToFile(this.Imgsrc, 'user_img');
			  console.log(file2);
			  this.$emit('onImgDate',file2);
			  this.recClose()
		  },
	},
	computed: {}
};
</script>
<style lang="scss" scoped="scoped">
	.canvasImg{
		width: 300px;
		height: 300px;
	}
	.buttons{
		margin: 20px auto;
		width: 4.9rem;
	}
	.qhIcon{
		width: 20px;
		height: 20px;
	}
</style>
