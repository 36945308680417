<template>
  <!-- 聊天室 --患者/医生-》视频会议 -->
  <div class="ltsPage">
    <div class="dw-top" ref="dwtop" >
      <van-notice-bar wrapable :scrollable="false" mode="closeable" @close="getTopSize">
        专家建议仅供参考，具体诊疗请在线下医生指导下进行。
      </van-notice-bar>
      <div
        v-if="topObj && topObj.type == 1"
        @click="showSpYy('yy')"
        class="bot-msg bot-msg-vol1"
      >
        <span> 当前语音通话等待接听 </span>
        <span class="zixun">
          接听
          <img src="../assets/img/lft2-white.png" alt="" style="width: 20px" />
        </span>
      </div>
      <div
        v-else-if="topObj && topObj.type == 2"
        @click="showSpYy('sp')"
        class="bot-msg bot-msg-vol2"
      >
        <span> 当前视频通话等待接听 </span>
        <span class="zixun">
          接听
          <img src="../assets/img/lft2-white.png" alt="" style="width: 20px" />
        </span>
      </div>
    </div>

    <!-- <div class="view" ref="listView" :style="{ height: 'calc(100vh - 90px - ' + (isShow.elseFeature ? '248px' : '0px') + ' )' }"> -->
    <van-pull-refresh
      v-model="isLoading.refresh"
      class="view"
      @refresh="onRefresh"
	   @click.native="isShow.elseFeature = false"
      ref="listView" >
      <div :style="{'padding-top':topHeigth}" class="list" ref="list">
        <template v-for="item in list">
          <div class="tsText item" v-if="item.msgType == 9">
            {{ item.content || "" }}
          </div>
          <div v-else class="item">
            <div class="my" v-if="item.sendType == 2">
              <div class="textAndName">
                <div class="createTime">{{ item.createTime || "" }}</div>
                <div class="userName">{{ item.name || getName(item) }}</div>
                <div class="text" :class="item.msgType == 1 ? 'text-bg' : ''">
                  <span style="white-space: pre-line;" v-if="item.msgType == 1">
                    {{ item.content || "" }}
                  </span>
                  <img
                    @click="showbigImg([item.content])"
                    class="wsImg"
                    v-else-if="item.msgType == 2"
                    :src="item.content"
                  />
                  <!-- <a
                    v-else-if="item.msgType == 6"
                    target="_blank"
                    :href="item.content"
                    ><img src="../assets/img/icon/wenjian.png"
                  /></a> -->
                  <!-- <a
                    target="_blank"
                    :href="item.fileUrl"
                    v-else-if="item.msgType == 6"
                    class="download-box"
                  >
                    <p class="file-name">
                      <span>{{ item.fileName || item.fileUrl }}</span>
                    </p>
                    <img src="../assets/img/icon/wenjian.png" />
                  </a> -->
                  <div v-else-if="item.msgType == 6">
                    <div
                      v-if="
                        ['mp4', 'rmvb', 'avi', 'ts'].includes(item.fileTypeVal)
                      "
                    >
                      <video
                        @play="controlSingleVideo"
                        :src="item.fileUrl"
                        style="height: 200px; width: 100%"
                        controls="controls"
                      ></video>
                    </div>
                    <span
                      v-else
                     @click="loadFile(item.fileUrl,item.fileName || item.fileUrl)"
                      class="download-box"
                    >
                      <p class="file-name">
                        <span>{{ item.fileName || item.fileUrl }}</span>
                      </p>
                      <img src="../assets/img/icon/wenjian.png" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="headIcon">
                <img :src="item.img || getHeadIcon(item)" />
              </div>
            </div>
            <div v-else class="you">
              <div class="headIcon">
                <img :src="item.img || getHeadIcon(item)" />
              </div>
              <div class="textAndName">
                <div class="createTime">{{ item.createTime || "" }}</div>
                <div class="userName">{{ item.name || getName(item) }}</div>
                <div class="text">
                  <span style="white-space: pre-line;" v-if="item.msgType == 1">
                    {{ item.content || "" }}
                  </span>
                  <img
                    class="wsImg"
                    @click="showbigImg([item.content])"
                    v-else-if="item.msgType == 2"
                    :src="item.content"
                  />
                  <!-- <a
                    v-else-if="item.msgType == 6"
                    target="_blank"
                    :href="item.content"
                    ><img src="../assets/img/icon/wenjian.png"
                  /></a> -->
                  <!-- <a
                    target="_blank"
                    :href="item.fileUrl"
                    v-else-if="item.msgType == 6"
                    class="download-box"
                  >
                    <p class="file-name">
                      <span>{{ item.fileName || item.fileUrl }}</span>
                    </p>
                    <img src="../assets/img/icon/wenjian.png" />
                  </a> -->
                  <div v-else-if="item.msgType == 6">
                    <div
                      v-if="
                        ['mp4', 'rmvb', 'avi', 'ts'].includes(item.fileTypeVal)
                      "
                    >
                      <video
                        @play="controlSingleVideo"
                        :src="item.fileUrl"
                        style="height: 200px; width: 100%"
                        controls="controls"
                      ></video>
                    </div>
                    <span
                      v-else
                       @click="loadFile(item.fileUrl,item.fileName || item.fileUrl)"
                      class="download-box"
                    >
                      <p class="file-name">
                        <span>{{ item.fileName || item.fileUrl }}</span>
                      </p>
                      <img src="../assets/img/icon/wenjian.png" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </van-pull-refresh>
    <!-- </div> -->
    <div
	ref="bottomView"
      class="bottomView"
    >
      <div class="bottomView-tob1">
        <div :style="{ color: dsColor }" class="tdate">{{ dateNum }}</div>
        <div @click="inviteFamily">邀请家属</div>
      </div>
      <div>
        <div class="bottomView-tob2">
          <!-- <VoiceAdd><img src="../assets/img/icon/huatong.png" /></VoiceAdd> -->
          <!-- <input @focus="isShow.elseFeature = false" v-model="inputText" />
		  <van-button v-show="inputText" size="small" type="primary" @click="sendText" >发送</van-button>
          <img
		  v-show="!inputText"
            @click="changeElseFeature"
            src="../assets/img/icon/gengduo.png"
          /> -->
		  <van-field
		    v-model="inputText"
		    rows="1"
		    size="large"
		    :autosize=" { maxHeight: 200, minHeight: 30 }"
		    label=""
		    @focus="hideElseFeature"
		    type="textarea"
		    class="inputText"
		    placeholder="请输入"
		  >
		  <!-- extra -->
		  <template #extra>
		      <div >
		  		<div style="height: 36px;overflow: hidden;margin-left: 6px;">
		  			<van-button
		  			 style="height: 32px;line-height: 32px;"
		  			 v-show="inputText" size="small" type="primary" @click="sendText" >发送</van-button>
		  			<img
		  			v-show="!inputText"
		  			  @click="changeElseFeature"
		  			  src="../assets/img/icon/gengduo.png"
		  			/>
		  		</div>
		  	</div>
		      </template>
		  </van-field>
        </div>
      </div>
      <!-- 其他功能显示 -->
      <div v-show="isShow.elseFeature" ref="elseFeature" class="elseFeature">
        <div class="elseFeature-item">
          <div>
            <label>
              <input
                type="file"
                @change="sendImg"
                accept="image/*"
                style="display: none"
              />
              <img src="../assets/img/icon/xiangce.png" />
            </label>
          </div>
          <p>相册</p>
        </div>
        <div v-show="!isShow.sp" class="elseFeature-item">
          <div>
            <photograph @onImgDate="sendImg_ps"
              ><img src="../assets/img/icon/baishe.png"
            /></photograph>
          </div>
          <p>拍摄</p>
        </div>
        <div @click="showSpYy('sp')" class="elseFeature-item">
          <div><img src="../assets/img/icon/shiping.png" /></div>
          <p>视频</p>
        </div>
        <div @click="showSpYy('yy')" class="elseFeature-item">
          <div><img src="../assets/img/icon/yuyin.png" /></div>
          <p>语音</p>
        </div>
        <div class="elseFeature-item">
          <div>
            <label>
              <input type="file" @change="sendFile" style="display: none" />
              <img src="../assets/img/icon/wenjian.png" />
            </label>
          </div>
          <p>文件</p>
        </div>
        <div @click="showJzrInfo" class="elseFeature-item">
          <div><img src="../assets/img/icon/pat.png" /></div>
          <p>患者信息</p>
        </div>
		<!-- <div>
			<div @click="$myws.close" >手动断开</div>
			<div @click="$myws.initMyWs($cache.local.getItem('patientId'))">连接</div>
		</div> -->
      </div>
    </div>

    <!-- 邀请家属弹框 -->
    <van-dialog
      v-model:show="isShow.inviteFamily"
      title="邀请家属"
      confirm-button-text="复制"
      @confirm="copyText()"
      show-cancel-button
    >
      <div class="inviteFamilyText">
        <p>患者您好</p>
        <p>
          您可邀请一位家属协助您一并参与沟通。已为您生成分享链接，微信发送给您的家属，打开微信后验证通过即可参与沟通。
        </p>
      </div>
      <div class="inviteFamilyYzm">
        验证码：
        <span class="inviteFamilyYzmNum">{{
          inviteFamilyObj.invitationCode
        }}</span>
      </div>
      <div class="inviteFamilyLj">
        连接：
        <span class="inviteFamilyLjColor">{{
          inviteFamilyObj.invitationUrl
        }}</span>
      </div>
    </van-dialog>

    <!-- 视频聊天 -->
    <video-conferencing
      ref="sp"
      v-show="isShow.spView"
      v-model:show="isShow.sp"
      :activeUser="activeUser"
	   :userObj="userObj"
      :userId="userId"
    ></video-conferencing>
    <!-- 语音聊天 -->
    <audio-conferencing
      ref="yy"
      v-show="isShow.yyView"
      v-model:show="isShow.yy"
      :activeUser="activeUser"
	   :userObj="userObj"
      :userId="userId"
    ></audio-conferencing>
    <!-- 视频/音频隐藏时的按钮 -->
    <div class="fk" v-show="isShow.sp || isShow.yy">
      <div @click="isShow.spView = !isShow.spView" v-show="isShow.sp">
        视频{{ isShow.spView ? "隐藏" : "显示" }}
      </div>
      <div @click="isShow.yyView = !isShow.yyView" v-show="isShow.yy">
        音频{{ isShow.yyView ? "隐藏" : "显示" }}
      </div>
    </div>

    <!-- 就诊人信息 -->
    <van-popup
      v-model:show="isShow.hzInfo"
      get-container="body"
      position="bottom"
    >
      <div
        style="
          display: flex;
          justify-content: flex-end;
          line-height: 32px;
          color: #00a8ff;
          padding: 6px 16px;
          font-size: 16px;
        "
      >
        <span @click="isShow.hzInfo = false">关闭</span>
      </div>
      <jzr-info
	  type="2"
        :roomId="$route.query.roomId"
        v-model:show="isShow.hzInfo"
      ></jzr-info>
    </van-popup>
  </div>
</template>

<script>
import {
  Toast,
  Dialog,
  PullRefresh,
  ImagePreview,
  Popup,
  NoticeBar,
  Button,
  Field
} from "vant";
import VoiceAdd from "../components/voice/VoiceAdd.vue";
import photograph from "../components/img/photograph.vue";
import videoConferencing from "../components/compRoom/voice/videoConferencing";
import audioConferencing from "../components/compRoom/audio/audioConferencing";
import jzrInfo from "../components/compRoom/mokuai/jzrInfo.vue";
// 图片压缩
import lossyImg from "../mixins/lossyImg.js";
let jiashuImg = require("../assets/img/icon/jiashu.png");
const yizhuImg = require("../assets/img/icon/yizhu1.png");
const yishengImg = require("../assets/img/icon/yisheng.png");
const zhuanjiaImg = require("../assets/img/icon/zhuanjia.png");
const huanzheImg = require("../assets/img/icon/huanzhe.png");
export default {
  name: "chatRoom",
  components: {
    VoiceAdd,
    photograph,
    videoConferencing,
    audioConferencing,
    jzrInfo,
    [Dialog.Component.name]: Dialog.Component,
    [PullRefresh.name]: PullRefresh,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
	[Button.name]:Button,
	[Field.name]:Field,
  },
  provide() {
    return {
      sendVARoomState: this.sendVARoomState,
    };
  },
  data() {
    return {
      list: [],
      dsColor: "#000",
      dateNum: "",
      ds: null,
      wsds: null,
      inputText: "",
      isShow: {
        elseFeature: false,
        sp: false,
        yy: false,
        spView: false,
        yyView: false,
        hzInfo: false,
      },
      isLoading: {
        refresh: false,
      },
      roomId: {},
      userObj: {},
      // 邀请家属相关参数
      inviteFamilyObj: {},
      activeUser: {},
      userId: "",
      mediaStateList: {},
      topObj: null,
      getMediaStateDs: null,
      // 房间信息
      roomInfo: {},
	  // 顶部高度
	  topHeigth:0,
    };
  },
  mixins: [lossyImg],
  watch: {
    "$store.state.wsNewMsg"(i) {
      // console.error("新消息", i);
      if (i && i.roomId == this.roomId) {
        // 标记为已读
        this.$http
          .request("upMsgRead", {
            roomId: this.roomId,
            patientId: this.$cache.local.getItem("patientId"),
          })
          .then((res) => {})
          .catch(() => {});
        this.gddb();
      }
    },
    "$store.state.mediaState"(item) {
      if (item.roomId == this.roomId) {
        this.setMediaState(item);
      }
    },
    // 获取会诊状态
    "$store.state.changeRoomTypeObj"(item) {
      // if是当前会诊 获取会诊状态

      if (item.msgContent) {
        try {
          let m = JSON.parse(item.msgContent);
          if (this.roomId == m.id) {
            if (m.roomStatus == 0) {
              // 停止定时 会诊未开始
              if (this.ds) {
                window.clearInterval(this.ds);
              }
              this.dsColor = "#999";
              this.dateNum = "会诊尚未开始";
            } else if (m.roomStatus == 1) {
              if (this.roomInfo.roomStatus == 1) {
                // 正常执行中
              } else if (this.roomInfo.roomStatus == "0") {
                // 未开始 变成开始》初始化 并重新获取 聊天室信息 roomInfo
                this.getRoomInfo();
              } else if (this.roomInfo.roomStatus == 2) {
                // 已完成 的 变成已结束 没有此操作
              }
            } else if (m.roomStatus == 2) {
              // 停止定时 会诊已结束
              this.dsColor = "#999";
              this.dateNum = "会诊已结束";
            }
          }
        } catch {}
      }
    },
    // 监听会诊时间修改
    "$store.state.changeYuyueObj"(item) {
      if (item.msgContent) {
        try {
          let m = JSON.parse(item.msgContent);
          if (this.roomId == m.id) {
            this.getRoomInfo();
          }
        } catch {}
      }
    },
    // 监听会诊结束
    "$store.state.roomEndObj"(item) {
      if (item.roomId == this.roomId) {
        // 停止视频或者音频通话
        this.$nextTick(() => {
          this.$refs.sp.recClose();
          this.$refs.yy.recClose();
          Toast("您本次会诊已结束，祝您健康！");
        });
        if (this.ds) {
          window.clearInterval(this.ds);
          this.dsColor = "#999";
          this.dateNum = "会诊已结束";
          this.roomInfo.roomStatus = 2;
          this.topObj = null;
        }
      }
    },
	"topObj"(){
		this.getTopSize()
	},
	// 监听系统时间初始化
	"$store.state.serverTimeState"(t){
		console.log("----------111",t)
		if(t==1){
			this.getRoomInfo()
		}
	},
  },
  created() {
    console.log("进入聊天室");
    Toast.allowMultiple();
    this.userId =this.userId =this.$route.query.seeDoctorId //this.$cache.local.getItem("patientId");
    this.activeUser = {
      roomId: this.$route.query.roomId,
    };
    this.roomId = this.$route.query.roomId;
	if(this.roomId){
		this.inputText=this.$cache.getRoomInputValue(this.roomId)
	}
    //获取历史记录
    this.getUserInfoList();
    // 发送ws ---确认连接
    this.$nextTick(() => {
      this.$myws.send(
        `{"msgType":"ROOM MEMBER DATA","data":{"patientId":${this.userId},"roomId":${this.roomId}}}`
      );
    });
    if (this.wsds) {
      window.clearInterval(this.wsds);
    }
    this.wsds = window.setInterval(() => {
      this.$myws.send(
        `{"msgType":"ROOM MEMBER DATA","data":{"patientId":${this.userId},"roomId":${this.roomId}}}`
      );
    }, 5 * 1000);
    // 获取聊天室信息
    this.getRoomInfo();

    this.getMediaState();
    if (this.getMediaStateDs) {
      window.clearInterval(this.getMediaStateDs);
    }
    this.getMediaStateDs = window.setTimeout(() => {
      this.getMediaState();
    }, 10000);
  },
  mounted() {
	  this.getTopSize();
  },
  methods: {
    // 控制同一页面只有一个音频或视频在播放
    controlSingleVideo() {
      var videoList = document.getElementsByTagName("video");
      console.log(videoList, "videoList");
      for (let i = 0; i < videoList.length; i++) {
        let video = videoList[i];
        video.addEventListener("play", function (e) {
          console.log(video);
          console.log("提示该视频正在播放中");
          for (let j = 0; j < videoList.length; j++) {
            if (j !== i) videoList[j].pause();
          }
          for (let j = 0; j < audioList.length; j++) {
            audioList[j].pause();
          }
        });
      }
    },
    // 聊天室信息
    getRoomInfo() {
      this.$http
        .request("getRoomInfo", {
          id: this.roomId,
        })
        .then((res) => {
          if (this.ds) {
            window.clearInterval(this.ds);
          }
          this.roomInfo = res;
          let st = res.startConsultTime;
          st = st.replace(/-/g, "/");
          st = new Date(st).getTime();
          // 时长
          let sc = res.consultationDuration || 0;
          // 结束时间
          let et = st + sc * 60 * 1000;
          let nt = new Date(this.$fwDate).getTime();
          if (res.roomStatus == 1) {
			    console.log('倒计时-----',res)
			  			  // 时长 实际（定时）时长 当前-预约开始时长
			  			  let scs= Math.floor((nt-st) / 1000);
			              if (scs>=0) {
			                // let s = Math.floor((et - nt) / 1000);
			  			  // this.dsColor="#272d3b";
			                this.ds = window.setInterval(() => {
			  					scs+=1;
			                  let ss = scs % 60;
			                  // 分钟
			                  let sf = (scs - ss) / 60;
			                  let sm=sf%60;
			                  // 小时
			                  let sh=(scs-ss-(sm*60))/3600
			                  this.dateNum =
			                    (sh > 9 ? sh : "0" + sh)+":"+(sm > 9 ? sm : "0" + sm) + ":" + (ss > 9 ? ss : "0" + ss);
			  				  if(scs>sc*60){
			  					  this.dsColor="red"
			  				  }else{
			  					  this.dsColor="#272d3b";
			  				  }
			                }, 1000);
			              }else{
			  				console.error("不执行计时",st,et,nt );
			                this.dateNum = "";
			  			  this.hzksdjs(st-nt)
			              }
			            
		  } else if (res.roomStatus == 0) {
            this.dsColor = "#999";
            this.dateNum = "会诊尚未开始";
            this.hzksdjs(st - nt);
          } else if (res.roomStatus == 2) {
            this.dsColor = "#999";
            this.dateNum = "会诊已结束";
          } else {
            // 不存在
            this.dsColor = "#999";
            this.dateNum = "--:--";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 会诊开始倒计时
    hzksdjs(s) {
      if (s > 24 * 60 * 60 * 1000) {
        return;
      }
      if (s >= 0) {
        this.ds = window.setTimeout(() => {
          this.getRoomInfo();
        }, s + 1000);
      }
    },
    // 超时定时时长计算
    getTimeoutDs(s = 0) {
      // 倒计时 字体颜色更改
      this.dsColor = "red";
      s = Math.floor(s / 1000);
      this.ds = window.setInterval(() => {
        s++;
        let ss = s % 60;
        // 分钟
        let sf = (s - ss) / 60;
        let sm = sf % 60;
        // 小时
        let sh = (s - ss - sm * 60) / 3600;
        this.dateNum =
          (sh > 9 ? sh : "0" + sh) +
          ":" +
          (sm > 9 ? sm : "0" + sm) +
          ":" +
          (ss > 9 ? ss : "0" + ss);
      }, 1000);
    },

    // 人员列表 -所有
    getUserInfoList() {
      if (!this.roomId) {
        return;
      }
      this.$http
        .request("getRoomUserList", {
          roomId: this.roomId,
        })
        .then((res) => {
          res = res || [];

          let userObj = {};
          for (let i in res) {
            userObj["Z_" + res[i].sendType + "_" + res[i].id] = res[i];
          }
          this.userObj = userObj;
          this.getList(20, "init");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 上拉加载
    onRefresh() {
      this.getList(20, "push", () => {
        this.isLoading.refresh = false;
      });
    },
    // 获取聊天记录列表
    getList(limit = 20, t, h) {
      if (!this.roomId) {
        console.log("没有获取到房间号");
        if (h) {
          h();
        }
        return;
      }
      console.log(t);
      if (limit > 100) {
        limit = 100;
      } else if (limit > 20) {
        limit = limit;
      } else {
        limit = 20;
      }
      let f = {
        roomId: this.roomId,
        limit: limit,
        offset: 0,
      };
      let wornLh = 0;
      if (t == "init") {
        this.list = [];
      } else {
        try {
          wornLh = this.$refs.list.offsetHeight;
        } catch {}
      }
      // id
      if (this.list[0] && this.list[0].id) {
        f.id = this.list[0].id;
      }
      this.$http
        .request("getChatRoomMsgList", f)
        .then((res) => {
          if (h) {
            h();
          }
          let list = res || [];
          if (list.length == 0) {
            Toast("没有更多信息了");
            // 重置
            this.$store.commit("resetWsMsgList", {
              roomId: this.roomId,
              list: this.list,
            });
            return;
          }
          for (let i = 0; i < list.length; i++) {
            if (list[i].msgType == 6) {
              list[i].fileUrl = list[i].content.split(",")[0];
              list[i].fileName = list[i].content.split(",")[1];
              //   获取最后一个"."之后的内容
              let index = list[i].fileUrl.lastIndexOf(".");
              list[i].fileTypeVal = list[i].fileUrl.substring(
                index + 1,
                list[i].fileUrl.length
              );
            }
            list[i].img = this.getHeadIcon(list[i]);
            list[i].name = this.getName(list[i]);
            this.list.unshift(list[i]);
          }
          // 对消息列表中的数据进行浅拷贝至vuex中
          // 重置
          this.$store.commit("resetWsMsgList", {
            roomId: this.roomId,
            list: this.list,
          });
          if (t == "init") {
            this.$nextTick(() => {
              console.log("滚动到最下方");
              if (this.list.length > 0) {
                let sh = this.$refs.listView.$el.offsetHeight;
                let lh = this.$refs.list.offsetHeight;
                if (lh > sh) {
                  this.$refs.listView.$el.scrollTop = lh - sh + 10;
                  console.log(lh, sh, lh - sh);
                }
              } else {
                console.error("空");
              }
              this.$http
                .request("upMsgRead", {
                  roomId: this.roomId,
                  patientId: this.$cache.local.getItem("patientId"),
                })
                .then((res) => {})
                .catch(() => {});
            });
          } else {
            this.$nextTick(() => {
              console.log("滚动到加载前的第一条数据");
              let lh = this.$refs.list.offsetHeight;
              this.$refs.listView.$el.scrollTop = lh - wornLh + 10;
            });
          }
        })
        .catch((e) => {
          if (h) {
            h();
          }
          console.error(e);
        });
    },
    // 获取头像
    getHeadIcon(item) {
      try {
        if (item.sendType == 4) {
          return jiashuImg;
        } else if (item.sendType == 1) {
          return this.userObj["Z_1_" + item.accountId].headImg || yizhuImg;
        } else if (item.sendType == 2) {
          return this.userObj["Z_2_" + item.seeDoctorId].headImg || huanzheImg;
        } else if (item.sendType == 3) {
          // return this.userObj["Z_3_" + item.doctorId].headImg || yishengImg;
		  return this.userObj["Z_3_" + item.doctorId].headImg || (item.doctorType!=1?yishengImg:zhuanjiaImg) ;
        }
      } catch {
        // userObj["Z_"+res[i].sendType+"_"+res[i].id]=res[i]
        return "";
      }
    },
    // 获取称呼
    getName(item) {
      try {
        if (item.sendType == 4) {
          return "家属";
        } else if (item.sendType == 1) {
          return this.userObj["Z_1_" + item.accountId].memberName;
        } else if (item.sendType == 2) {
          return this.userObj["Z_2_" + item.seeDoctorId].memberName;
        } else if (item.sendType == 3) {
          return this.userObj["Z_3_" + item.doctorId].memberName;
        }
      } catch {
        return "";
      }
    },
    listScroll(e) {
      // console.log(e.target.scrollTop)
    },
    // 发送文字消息
    sendText() {
      if (!this.inputText) {
        return;
      }
      let f = {
        patientId: this.$cache.local.getItem("patientId"),
        roomId: this.roomId,
        content: this.inputText,
        msgType: 1,
        sendType: 2,
        seeDoctorId: this.$route.query.seeDoctorId,
      };
	  this.inputText = "";
      this.$http
        .request("sendRoomMsg", f)
        .then((res) => {
			f.id=res.id;
          f.createTime = res.createTime;
          this.list.push(f);
          this.$emit("sendMsg", f);
          this.gddb();
          this.inputText = "";
        })
        .catch(() => {
			this.inputText = f.content;
          console.log("操作失败请再次操作");
        });
    },
    // 拍摄照片进行上传
    sendImg_ps(f) {
      Toast("请稍等");
      let newF = new FormData();
      newF.append("fileType ", "1");
      newF.append("imgAbridge  ", false);
      newF.append("file", f);
      newF.append("roomId ", this.roomId);
      let tload = Toast.loading({
        duration: 0,
        message: "请稍等...",
      });
      this.$http
        .request("roomUploadImg", newF)
        .then((res) => {
          console.log("文件上传成功", res);
          this.sendFileUrl(2, res.bigImg);
          tload.clear();
          e.target.value = "";
        })
        .catch(() => {
          tload.clear();
          e.target.value = "";
        });
    },
    // 选择图片 并发送
    sendImg(e) {
      if (e.target.files[0]) {
        this.compressorImage(e.target.files[0])
          .then((fl) => {
            let newF = new FormData();
            newF.append("fileType ", "1");
            newF.append("imgAbridge  ", false);
            newF.append("file", fl);
            newF.append("roomId ", this.roomId);
            let tload = Toast.loading({
              duration: 0,
              message: "请稍等...",
            });
            this.$http
              .request("roomUploadImg", newF)
              .then((res) => {
                console.log("文件上传成功");
                // 获取到文件地址
                this.sendFileUrl(2, res.bigImg);
                tload.clear();
                e.target.value = "";
              })
              .catch(() => {
                tload.clear();
              });
          })
          .catch(() => {});
      }
    },
    // 选择文件 并上传
    sendFile(e) {
      console.log(e.target.files[0], "上传文件");
      if (e.target.files[0]) {
        let newF = new FormData();
        newF.append("fileType ", "0");
        newF.append("imgAbridge  ", false);
        newF.append("file", e.target.files[0]);
        newF.append("roomId ", this.roomId);
        let tload = Toast.loading({
          duration: 0,
          message: "请稍等...",
        });
        this.$http
          .request("roomUploadImg", newF)
          .then((res) => {
            console.log("文件上传成功", res);
            this.sendFileUrl(6, res.bigImg, e.target.files[0].name);
            tload.clear();
            // 获取到文件地址
            // ws 发送文件地址
            e.target.value = "";
          })
          .catch(() => {
            tload.clear();
          });
      }
    },
    // 发送图片、文件地址
    sendFileUrl(t, u, name) {
      let str = "";
      if (t == 6 && name) {
        str = u + "," + name;
      } else {
        str = u;
      }
      let f = {
        patientId: this.$cache.local.getItem("patientId"),
        content: str,
        msgType: t,
        roomId: this.roomId,
        sendType: 2,
		seeDoctorId: this.$route.query.seeDoctorId,
      };
      this.$http
        .request("sendRoomMsg", f)
        .then((res) => {
			f.id=res.id;
			f.createTime = res.createTime;
          Toast("发送成功");
          if (f.msgType == 6) {
            f.fileUrl = f.content.split(",")[0];
            f.fileName = f.content.split(",")[1];
            //   获取最后一个"."之后的内容
            let index = f.fileUrl.lastIndexOf(".");
            f.fileTypeVal = f.fileUrl.substring(index + 1, f.fileUrl.length);
          }
          
          this.list.push(f);
          this.$emit("sendMsg", f);
          this.gddb();
        })
        .catch(() => {});
    },
    // 同步病历资料
    syncCaseData(i) {},
    // 聊天内容添加
    chatRoomContentAdd(f) {
      let ff = {
        patientId: this.$cache.local.getItem("patientId"),
        content: JSON.stringify(f),
        roomId: this.roomId,
        sendType: 2,
        msgType: f.msgType,
		seeDoctorId: this.$route.query.seeDoctorId,
      };
      this.$http
        .request("sendRoomMsg", ff)
        .then((res) => {
			ff.id=res.id;
			ff.createTime = res.createTime;
          console.log("发送成功", ff);
          
          this.list.push(ff);
          this.$emit("sendMsg", ff);
        })
        .catch(() => {});
    },
    // 邀请家属
    inviteFamily() {
      let t = Toast("请稍等");
      this.inviteFamilyObj = {};
      this.$http
        .request("inviteFamily", { roomId: this.roomId })
        .then((res) => {
          t.clear();
          console.log(res);
          this.inviteFamilyObj = res;
          this.isShow.inviteFamily = true;
        })
        .catch(() => {});
    },
    // 复制文字
    copyText() {
      let input = document.createElement("input");
      input.value = `验证码：${this.inviteFamilyObj.invitationCode},连接：${this.inviteFamilyObj.invitationUrl}?roomId=${this.roomId}`;
      //添加input标签
      document.body.appendChild(input);
      //选中input标签
      input.select();
      //执行复制
      document.execCommand("copy");
      //移除input标签
      document.body.removeChild(input);
      Toast("复制成功");
    },
    // 显示视频、语音
    showSpYy(t) {
      if (t == "sp") {
        if (this.isShow.yy) {
          Toast("请先结束语音通话");
          return;
        }
        this.isShow.spView = true;
        this.isShow.sp = true;
      } else if (t == "yy") {
        if (this.isShow.sp) {
          Toast("请先结束视频通话");
          return;
        }
        this.isShow.yyView = true;
        this.isShow.yy = true;
      }
    },
    gddb() {
      this.$nextTick(() => {
        console.log("滚动到最下方");
        if (this.list.length > 0) {
          let sh = this.$refs.listView.$el.offsetHeight;
          let lh = this.$refs.list.offsetHeight;
          if (lh > sh) {
            this.$refs.listView.$el.scrollTop = lh - sh + 10;
            console.log(lh, sh, lh - sh);
          }
        } else {
          console.error("空");
        }
      });
    },
    // 预览图片
    showbigImg(imgList) {
      ImagePreview(imgList);
    },
    /*
	 @param {type} t 1语音开启 2视频开启 3语音关闭 4视频关闭
	 */
    sendVARoomState(t) {
      console.error("语音视频状态", t);
      let f = {
        msgType: "MEDIA DATA",
        data: {
          patientId: this.$cache.local.getItem("patientId"),
          roomId: this.activeUser.roomId,
          content: "",
          callStatus: t,
          sendType: 2,
        },
      };
      this.$myws.send(JSON.stringify(f));
    },
    // 设置聊天室人员聊天状态
    setMediaState(item) {
      let sendId = "";
      if (item.sendType == 1) {
        sendId = item.accountId;
      } else if (item.sendType == 2) {
        sendId = item.patientId;
      } else if (item.sendType == 3) {
        sendId = item.doctorId;
      } else if (item.sendType == 4) {
        sendId = item.patientFamilyId;
      }
      if (item.callStatus == 1) {
        this.mediaStateList["S1_" + item.sendType + "_" + sendId] =
          new Date().getTime();
      } else if (item.callStatus == 2) {
        this.mediaStateList["S2_" + item.sendType + "_" + sendId] =
          new Date().getTime();
      } else if (item.callStatus == 3) {
        this.mediaStateList["S1_" + item.sendType + "_" + sendId] = 0;
      } else if (item.callStatus == 4) {
        this.mediaStateList["S2_" + item.sendType + "_" + sendId] = 0;
      }
      this.getMediaState();
    },
    // 获取连接语音视频个数 语音/视频 只存在一个
    getMediaState() {
      let n = new Date().getTime();
      let topObj = null;
      if (this.roomInfo.roomStatus == 2) {
        this.topObj = topObj;
        return;
      }
      for (let i in this.mediaStateList) {
        if (n - this.mediaStateList[i] <= 12000) {
          topObj = {};
          if (i.indexOf("S1") == 0) {
            topObj.type = 1;
          } else if (i.indexOf("S2") == 0) {
            topObj.type = 2;
          }
          break;
        }
      }
      console.error("执行", topObj);
      this.topObj = topObj;
    },
    showJzrInfo() {
      this.isShow.hzInfo = true;
    },
	// 切换更多显示、隐藏
	changeElseFeature(){
		this.isShow.elseFeature = !this.isShow.elseFeature;
	},
	// 获取头部高度
	getTopSize(){
		this.$nextTick(()=>{
			this.topHeigth= this.$refs.dwtop.clientHeight+'px';
		})
	},
	// 下载文件
	loadFile(fileUrl,fileName){
		this.$router.push({
			path:"/downFile",
			query:{
				fileUrl:fileUrl,
				fileName:fileName,
			}
		})
	},
	hideElseFeature(){
		this.isShow.elseFeature = false
		window.setTimeout(()=>{
			this.gddb()
		},200)
	},
  },
  beforeUnmount() {
    console.log("销毁");
	if(this.roomId){
		this.$cache.setRoomInputValue(this.roomId,this.inputText)
	}
    if (this.ds) {
      window.clearInterval(this.ds);
    }
    if (this.wsds) {
      window.clearInterval(this.wsds);
    }
    if (this.getMediaStateDs) {
      window.clearInterval(this.getMediaStateDs);
    }
  },
  beforeRouteLeave(to,from,next){
  	  let showText=""
  	  if(this.isShow.sp||this.isShow.yy){
  		  if(this.isShow.sp){
  			  showText="是否确认退出视频会诊？"
  		  }else if(this.isShow.yy){
  			  showText="是否确认退出语音会诊？"
  		  }
  		  Dialog.confirm({
  		    title: '提示',
  		     closeOnPopstate: false,
  		    message:showText,
  		  })
  		    .then((res) => {
  				try{
  					this.$refs.sp.recClose();
  					this.$refs.yy.recClose();
  				}catch{}
  		  		 next();
  		    })
  		    .catch((e) => {
  		  			next(false)
  		    });
  	  }else{
  		  next()
  	  }
  	
  },
};
</script>
<style scoped lang="scss">
.ltsPage{
	display: flex;
	height: 100vh;
	flex-direction:column;
}
.view {
  width: 100%;
  flex-shrink:1;
  height: 100%;
  background-color: #f4f4f4;
  box-sizing: border-box;
  padding-bottom: 10px;
  overflow: auto;
}
.list{
	overflow: hidden;
}
.item {
  margin-top: 22px;
  width: 100%;
}
.tsText {
  font-size: 13px;
  color: #999999;
  line-height: 26px;
  text-align: center;
}
.my {
  display: flex;
  justify-content: end;
  justify-content: flex-end;
  width: 100%;
  .text {
    border-radius: 8px 0 8px 8px;
    float: right;
    &.text-bg {
      background-color: #278eff;
      color: #fff;
    }
    color: #fff;
  }
  .createTime {
    float: right;
  }
  .userName {
    text-align: right;
    float: right;
  }
}
.you {
  width: 100%;
  display: flex;
  justify-content: start;
  .text {
    float: left;
    border-radius: 0 8px 8px 8px;
  }
  .userName {
    float: left;
  }
}
.textAndName {
  width: calc(100% - 130px);
  overflow: hidden;
}
.createTime {
  color: #5d6e7f;
  font-size: 12px;
}
.userName {
  width: 100%;
  margin: 4px 0;
  color: #5d6e7f;
  font-size: 13px;
}
.text {
  background-color: #fff;
  padding: 8px;
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  color: #27393b;
  word-break: break-all;
}
.headIcon {
  width: 56px;
  height: 56px;
  margin: 0 7px;
  // background-color: #fff;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.wsImg {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.bottomView {
  flex-shrink:0;
  border-top: 1px solid #eee;
  background-color: #f4f4f4;
  overflow: auto;
  .bottomView-tob1 {
    display: flex;
    > div {
      min-width: 1.7rem;
      // height: 20px;
      margin: 5px;
      padding: 5px 6px;
      line-height: 20px;
      background-color: #fff;
      &.tdate {
        text-align: center;
      }
    }
  }
  .bottomView-tob2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    > input {
      width: 6rem;
      height: 44px;
      border: 0;
      outline: 0;
      padding: 0 4px;
      border-radius: 4px;
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.elseFeature {
  overflow: hidden;
  margin-left: 0.27rem;
  margin-top: 10px;
  padding-bottom: 20px;
  .elseFeature-item {
    width: 1.28rem;
    margin: 10px 0.23rem 0;
    text-align: center;
    float: left;
    img {
      width: 1.28rem;
      height: 1.28rem;
    }
    p {
      margin-top: 2px;
      color: #5d6e7f;
    }
  }
}
.inviteFamilyText {
  font-size: 15px;
  font-weight: 400;
  color: #5d6e7f;
  line-height: 24px;
  padding: 10px;
}
.inviteFamilyYzm {
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #5d6e7f;
  .inviteFamilyYzmNum {
    font-weight: 600;
  }
}
.inviteFamilyLj {
  padding: 10px 10px 20px 10px;
  font-size: 14px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #5d6e7f;
  .inviteFamilyLjColor {
    color: #278eff;
  }
}
.fk {
  position: fixed;
  width: 100px;
  right: 0px;
  bottom: 100px;
  z-index: 1001;
  > div {
    height: 40px;
    line-height: 40px;
    color: #00a8ff;
    background-color: #fff;
    text-align: center;
    margin: 10px;
    box-shadow: 0 0 4px #ccc;
  }
}
.download-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  height: 80px;
  .file-name {
    span {
		color: #00a8ff;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-decoration: underline;
      font-style: italic;
      white-space: pre-wrap;
    }
  }
  img {
    width: 50px;
    height: 50px;
  }
}

.bot-msg {
  width: 100%;
  // background-color: #e8f3ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin: 15px;
  padding: 0 15px;
  box-sizing: border-box;
  // border-radius: 8px;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  bottom: 0;
  color: #fff;
  span {
    display: flex;
    align-items: center;
  }
}
.bot-msg-vol1 {
  background-color: rgba($color: #2ac2a8, $alpha: 0.7);
}
.bot-msg-vol2 {
  background-color: rgba($color: #f79c42, $alpha: 0.7);
}
.dw-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  // .hzItem{}
}
.inputText{
	align-items:flex-end;
	align-items:end;
	background:transparent;
	padding-left: 6;
	::v-deep(textarea){
		line-height: 30px;
		background: #fff;
		border-radius: 4px;
		padding: 4px 8px;
	}
}
</style>
