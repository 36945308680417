<template>
	<div class="page-wrapper">
		<span @click="recOpen"><slot></slot></span>
		<van-popup v-model:show="isLoading.record" get-container="body" position="bottom">
			<div style="display: flex;justify-content: space-between;line-height: 32px;color: #00a8ff;padding: 6px 16px;font-size: 16px;">
				<span @click="recClose">取消</span>
				<span @click="upTrans">确定</span>
			</div>
			<div class="transcribe-view">
				<div style="height: 60px;"><audio controls="controls" style="width: 100%;height: 50px;" v-if="recBlob" :src="getRecBlob"></audio></div>

				<div class="lz transcribe" @touchstart="recStart" @touchcancel="recStop" @touchend="recStop"><img src="../../assets/img/icon/huatong.png" /></div>
			</div>
		</van-popup>
	</div>
</template>
<script>
require('../../utils/recorder/recorder-core.js');
require('../../utils/recorder/mp3.js');
require('../../utils/recorder/mp3-engine.js');
import { Popup, Toast } from 'vant';
export default {
	name: 'voiceAdd',
	data() {
		return {
			fnName: '',
			formData: {
				//名称
				voiceName: '',
				//内容
				voiceFile: '',
				fileUrl: ''
			},
			uploadAudioList: [],
			//
			rec: null,
			wave: null,
			recBlob: null,
			isLoading: {
				record: false, //是否打开录音录音
				recirding: false //是否在录音中
			},
			s: [],
			hasTouch: ''
		};
	},
	components: {
		[Popup.name]: Popup
	},
	// 激活
	activated() {
		console.log('激活2');
	},
	deactivated() {
		console.log('失活2');
	},
	created() {
		let isTouchPad = /hp-tablet/gi.test(navigator.appVersion);
		this.hasTouch = 'ontouchstart' in window && !isTouchPad;
		// this.srartEv = hasTouch ? 'touchstart' : 'mousedown',
		//   this.moveEv = hasTouch ? 'touchmove' : 'mousemove',
		//    this.endEv = hasTouch ? 'touchend' : 'mouseup',
		//    this.cancelEv = hasTouch ? 'touchcancel' : 'mouseup';
	},
	methods: {
		// 上传录音
		beforeRead(file) {
			console.log(file, 'file');
			let formData = new FormData(); // 为上传文件定义一个formData对象
			formData.append('file', file);
			return;
			this.$http
				.request('uploadAudio', formData)
				.then(res => {
					console.log(res);
					this.formData.fileUrl = res;
				})
				.catch(err => {
					console.log(err);
				});

			return;
			let uploadUrl = URL.createObjectURL(file);
			console.log(uploadUrl, 'uploadUrl');
			this.uploadAudioList.push(file);
			this.uploadAudioList.forEach(item => {
				formData.append(item.name, item);
			});
			console.log(this.uploadAudioList, 'this.uploadAudioList');
			console.log(formData, 'formData');
		},
		// 上传自己录制的录音
		upTrans() {
			this.recClose();
			let file = this.recBlob;
			console.log(file, 'file');
			let formData = new FormData(); // 为上传文件定义一个formData对象
			// form.append("upfile",blob,"recorder.mp3")
			formData.append('file', file, 'recorder.mp3');
			return;
			this.$http
				.request('uploadAudio', formData)
				.then(res => {
					console.log(res);
					this.formData.fileUrl = res;
				})
				.catch(err => {
					console.log(err);
				});
		},
		isFileUrl() {
			console.log(this.formData);
			if (this.formData.fileUrl) {
				return true;
			}
			return false;
		},
		/* 录音调用--开始*/
		//录音
		recOpen() {
			if (this.isLoading.record) {
				// 正在录音中
				return;
			}
			let _this = this;
			this.rec = null;
			this.wave = null;
			this.recBlob = null;
			let t = Toast({
				message: '请稍等',
				position: 'bottom'
			});
			var newRec = Recorder({
				type: 'mp3',
				sampleRate: 16000,
				bitRate: 16, //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
				onProcess: function(buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd) {
					//录音实时回调，大约1秒调用12次本回调
					// document.querySelector(".recpowerx").style.width=powerLevel+"%";
					// document.querySelector(".recpowert").innerText=bufferDuration+" / "+powerLevel;
					//可视化图形绘制
					// wave.input(buffers[buffers.length-1],powerLevel,bufferSampleRate);
				}
			});
			// createDelayDialog(); //我们可以选择性的弹一个对话框：为了防止移动端浏览器存在第三种情况：用户忽略，并且（或者国产系统UC系）浏览器没有任何回调，此处demo省略了弹窗的代码
			newRec.open(
				function() {
					//打开麦克风授权获得相关资源
					// dialogCancel(); //如果开启了弹框，此处需要取消
					_this.s.push('已打开录音，可以点击录制开始录音了');
					t.clear()
					_this.rec = newRec;
					_this.isLoading.record = true;
					//此处创建这些音频可视化图形绘制浏览器支持妥妥的
					// _this.wave=Recorder.FrequencyHistogramView({elem:".recwave"});

					console.log('已打开录音，可以点击录制开始录音了', 2);
					// 开始录音//录音弹框
					// this.recStart()
				},
				function(msg, isUserNotAllow) {
					//用户拒绝未授权或不支持
					t.clear()
					// dialogCancel(); //如果开启了弹框，此处需要取消
					console.log((isUserNotAllow ? 'UserNotAllow，' : '') + '打开录音失败：' + msg, 1);
					_this.s.push((isUserNotAllow ? 'UserNotAllow，' : '') + '打开录音失败：' + msg);
				}
			);
			window.waitDialogClick = function() {
				// dialogCancel();
				if(t){
					t.clear()
				}
				_this.s.push("打开失败：权限请求被忽略，<span style='color:#f00'>用户主动点击的弹窗</span>");
				console.log("打开失败：权限请求被忽略，<span style='color:#f00'>用户主动点击的弹窗</span>", 1);
			};
		},
		// 判断是否以开启权限
		// 开始录音
		/**开始录音**/
		recStart() {
			//打开了录音后才能进行start、stop调用
			console.log('按下');
			let _this = this;
			this.isLoading.recirding = true;
			if (this.rec && this.isLoading.record == true) {
				_this.s.push('已开始录音...');
				_this.recBlob = null;
				_this.rec.start();
				console.log('已开始录音...');
			} else {
				_this.isLoading.recirding = false;
				_this.s.push('未打开录音...');
				console.log('未打开录音...');
			}
		},
		/**暂停录音**/
		recPause() {
			this.isLoading.recirding = false;
			if (this.rec && this.isLoading.record == true) {
				this.s.push('暂停录音');
				this.rec.pause();
			} else {
				console.log('未打开录音', 1);
			}
		},
		/**恢复录音**/
		recResume() {
			if (this.rec && this.isLoading.record == true) {
				this.rec.resume();
			} else {
				// reclog("未打开录音",1);
			}
		},
		/**结束录音，得到音频文件**/
		recStop() {
			console.log('抬起');
			let _this = this;
			if (!(this.rec && this.isLoading.record == true)) {
				console.log('未打开录音', 1);
				return;
			}
			this.rec.stop(
				function(blob, duration) {
					console.log(blob, (window.URL || webkitURL).createObjectURL(blob), '时长:' + duration + 'ms');

					_this.recBlob = blob;
					_this.isLoading.recirding = false;
					// reclog("已录制mp3："+duration+"ms "+blob.size+"字节，可以点击播放、上传了",2);
				},
				function(msg) {
					// reclog("录音失败:"+msg,1);
				}
			);
		},
		/**关闭录音，释放资源**/
		recClose() {
			this.isLoading.record = false;
			if (this.rec) {
				this.rec.close();
				// reclog("已关闭");
			} else {
				// reclog("未打开录音",1);
			}
		},
		record() {
			return;
			window.navigator.mediaDevices
				.getUserMedia({
					audio: true
				})
				.then(mediaStream => {
					console.log(mediaStream);
					alert('123456');
					// beginRecord(mediaStream);
				})
				.catch(err => {
					// 如果用户电脑没有麦克风设备或者用户拒绝了，或者连接出问题了等
					// 这里都会抛异常，并且通过err.name可以知道是哪种类型的错误
					console.error(err);
					alert('456');
				});
		}
		/* 录音调用结束*/
	},
	computed: {
		getRecBlob() {
			if (!this.recBlob) {
				return '';
			}
			return (window.URL || webkitURL).createObjectURL(this.recBlob);
		}
	}
};
</script>
<style lang="scss" scoped="scoped">
.age::v-deep(.van-field__control) {
	justify-content: end;
	height: 24px;
}

.transcribe-view {
	padding: 40px 0 60px;
}
.transcribe {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: #ddf2ff;
	text-align: center;
	margin: 20px auto 0;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
	}
}
</style>
