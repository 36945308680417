<template>
	<div class="page-wrapper">
		<!-- 视频会议 --弹出组件调用处 -->
		<!-- <span @click="recOpen"><slot></slot></span> -->
		
		<!-- <van-popup v-model:show="isLoading.record" style="height: 100vh;" get-container="body" position="bottom">
			<div style="display: flex;justify-content: space-between;line-height: 32px;color: #00a8ff;padding: 6px 16px;font-size: 16px;"><span @click="recClose">关闭</span></div>
			<div style="max-height: calc(100vh - 45px);overflow: auto;">
				<audioRoom ref="audioRoom" v-if="isLoading.record" :activeUser="activeUser" :myUserId="userId"  @leave="leave"></audioRoom>
			</div>
		</van-popup> -->
		<div  class="gdtk" v-show="show" >
			<audioRoom ref="audioRoom" v-if="show" :activeUser="activeUser" :userObj="userObj" :myUserId="userId"  @leave="leave"></audioRoom>
		</div>
	</div>
</template>

<script>
import audioRoom from './audioRoom.vue';
import {Popup} from 'vant';
export default {
	name: 'videoConferencing',
	components: {
		[Popup.name]:Popup,
		audioRoom,
	},
	props:["userId","activeUser","show","userObj"],
	data() {
		return {
			isLoading: {
				record: false
			}
		};
	},
	created() {},
	methods: {
		// 显示视频会议
		// recOpen() {},
		// 关闭回调
		handleClose(next){
			this.recClose()
			next();
		},
		// 关闭
		recClose() {
			// 退出房间 --关闭
			if(this.$refs.audioRoom){
				this.$refs.audioRoom.handleLeave();
			}else{
				this.$emit('update:show',false)
			}
			
		},
		// 视频离开 --回调
		leave() {
			// this.recClose()
			this.$emit('update:show',false)
		}
	},
	mounted() {}
};
</script>

<style lang="scss" scoped>
	.page-wrapper{
		z-index:1000;
	}
	.gdtk{
		position: fixed;
		height: 100vh;
		width: 100%;
		overflow: hidden;
		top: 0;
		left: 0;
		// z-index: 1000;
		background-color: #fff;
	}
</style>
