import Compressor from "compressorjs";
/**
 * @param image 图片
 * @param backType 需要返回的类型blob,file
 * @returns
 */
export default {
  data() {
    return {};
  },

  methods: {
    async compressorImage(image) {
      return new Promise((resolve, reject) => {
        new Compressor(image, {
          quality: 0.6,
          success(result) {
            let file = new File([result], image.name, { type: image.type });
            resolve(file);
          },
          error(err) {
            console.log("图片压缩失败---->>>>>", err);
            reject(err);
          },
        });
      });
    },
  },
};
