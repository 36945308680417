<template>
  <!-- 会诊详情 -->
  <div class="infoview">
    <div class="viewtop">
      <div class="item top">
        <span class="userName">{{ userInfo.name }}</span>
        <span v-if="userInfo.sex" class="userInfo">{{
          userInfo.sex == 2 ? "女" : "男"
        }}</span>
        <span v-if="userInfo.age" class="userInfo">{{ userInfo.age }}岁</span>
        <span class="userInfo">{{ userInfo.phone }}</span>
      </div>
      <div class="item tbg">
        <div v-if="userInfo.startConsultTime" class="ttr">
          <span class="ttd1">会诊时间</span>
          <span class="ttd2">{{ userInfo.startConsultTime }}</span>
        </div>
        <div class="ttr">
          <span class="ttd1">病情概述</span>
          <span class="ttd2">{{ userInfo.conditionContent }}</span>
        </div>
		<div class="ttr">
		  <span class="ttd1">就诊诉求</span>
		  <span class="ttd2">{{ userInfo.recountContent }}</span>
		</div>
      </div>
      <div class="item itemc">
        <div class="titles">
          <span>病历资料</span>
          <span>点击可查看大图</span>
        </div>
        <div class="imgs">
          <template v-for="(item, index) in imgList" :key="index">
            
            
            
          </template>
        </div>
		<!-- 文件 -->
		<div class="imgs" >
			<span v-for="item in fileList"  @click="loadFile(item.url,item.name || item.url)" class="download-box">
			  <p class="file-name">
			    <span>{{ item.name || item.url }}</span>
			  </p>
			  <img src="../../../assets/img/icon/wenjian.png" />
			</span>
		</div>
		<!-- 图片 -->
		<div class="imgs" >
			<img
			 v-for="(item,index) in imgList"
			  @click="wiewImg(index)"
			  :src="item.url"
			/>
		</div>
		<!-- 视频 -->
		<div class="imgs" >
			<video
			 v-for="item in videoList"
			  @play="controlSingleVideo"
			  :src="item.url"
			  style="height: 200px; width: 100%"
			  controls="controls"
			></video>
		</div>
		<!--  -->
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { NoticeBar, ImagePreview, Button, Toast } from "vant";
export default {
  name: "huiZenInfo",
  components: {
    [NoticeBar.name]: NoticeBar,
    [Button.name]: Button,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    seeDoctorId: {},
	roomId:{},
	type:{}
  },
  watch: {
    show: {
      handler: function (t) {
		  if(t){
			  if(this.type==1){
				  this.seeDoctorUserInfo(this.seeDoctorId);
			  }else if(this.type==2){
				  this.getRoomOrderInfo(this.roomId);
			  }
		  }
      },
      immediate: true,
    },
  },
  data() {
    return {
      // seeDoctorPeople: {},
      // assistantOrder: {},
      // patientAssistantConsult: {},
		// 病历资料图片列表
      imgList: [],
	  // 病历资料视频列表
	  videoList:[],
	  // 病历资料文件列表
	  fileList:[],
      userInfo: {},
    };
  },
  created() {
    // this.getAssistantOrderDetailById();
  },
  methods: {
    // 获取就诊人信息 ---咨询
    seeDoctorUserInfo(seeDoctorId) {
		if(!seeDoctorId){
			Toast("就诊人信息标识获取失败")
			return;
		}
      // 先清空
      let t = Toast("请稍等");
      this.hzInfo = {};
      this.$http
        .request("queryPatId", { id: seeDoctorId })
        .then((res) => {
          console.error("就诊人信息", res);
          this.userInfo = res||{};
          try {
             let list = JSON.parse(res.caseImg);
			 let imgList=[];
			 let videoList=[];
			 let fileList=[];
			 for(let i in list){
				 if(list[i].type&&list[i].type.indexOf('image/')==0){
					 imgList.push(list[i])
				 }else if(list[i].type&&list[i].type.indexOf('video/')==0){
					 videoList.push(list[i])
				 }else {
					 fileList.push(list[i])
				 }
			 }
			 this.imgList=imgList;
			 this.videoList=videoList;
			 this.fileList=fileList
          } catch {
			   this.imgList=[];
			   this.videoList=[];
			   this.fileList=[]
		  }

          // 获取信息不全 --看是否更换新的接口
          t.clear();
        })
        .catch(() => {
			// this.$emit("update:show",false)
		});
    },
	// 获取就诊人信息 --会诊
	getRoomOrderInfo(roomId) {
	  // 先清空
	  if (roomId) {
	    this.$http
	      .request("getRoomOrderInfo", { id: roomId })
	      .then((res) => {
          console.error("就诊人信息", res);
          this.userInfo = res||{};
          try {
             let list = JSON.parse(res.caseImg);
			 let imgList=[];
			 let videoList=[];
			 let fileList=[];
			 for(let i in list){
				 if(list[i].type&&list[i].type.indexOf('image/')==0){
					 imgList.push(list[i])
				 }else if(list[i].type&&list[i].type.indexOf('video/')==0){
					 videoList.push(list[i])
				 }else {
					 fileList.push(list[i])
				 }
			 }
			 this.imgList=imgList;
			 this.videoList=videoList;
			 this.fileList=fileList
          } catch {
			  this.imgList=[];
			  this.videoList=[];
			  this.fileList=[]
		  }

          // 获取信息不全 --看是否更换新的接口
          t.clear();
        })
	      .catch(() => {
			  			// this.$emit("update:show",false)
		  });
	  }
	},
	

    // 控制同一页面只有一个音频或视频在播放
    controlSingleVideo() {
      var videoList = document.getElementsByTagName("video");
      console.log(videoList, "videoList");
      for (let i = 0; i < videoList.length; i++) {
        let video = videoList[i];
        video.addEventListener("play", function (e) {
          console.log(video);
          console.log("提示该视频正在播放中");
          for (let j = 0; j < videoList.length; j++) {
            if (j !== i) videoList[j].pause();
          }
          for (let j = 0; j < audioList.length; j++) {
            audioList[j].pause();
          }
        });
      }
    },
    // 获取订单详情
    getAssistantOrderDetailById() {
      return;
      this.$http
        .request("getAssistantOrderDetailById", {
          id: this.$route.query.orderId,
        })
        .then((res) => {
          console.log(res);
          if (res.seeDoctorPeople) {
            this.seeDoctorPeople = res.seeDoctorPeople;
          }
          if (res.assistantOrder) {
            this.assistantOrder = res.assistantOrder;
          }
          if (res.patientAssistantConsult) {
            this.patientAssistantConsult = res.patientAssistantConsult;
            this.getImgList(this.patientAssistantConsult.caseImg);
          }
        })
        .catch(() => {});
    },
    // 获取图片
    getImgList(l) {
      if (!l) {
        this.imgList = [];
        return;
      }
      try {
        let list = JSON.parse(l);
        this.imgList = list;
      } catch {
        this.imgList = [];
      }
      console.log(this.imgList);
    },
    wiewImg(i) {
		let arr = [];
		this.imgList.forEach((item) => {
			arr.push(item.url);
		});
      ImagePreview({
        images: arr,
        startPosition: i,
      });
    },
	// 下载文件
	loadFile(fileUrl,fileName){
		this.$router.push({
			path:"/downFile",
			query:{
				fileUrl:fileUrl,
				fileName:fileName,
			}
		})
	},
  },
};
</script>
<style scoped lang="scss">
.infoview {
  background: #f4f4f7;
  height: calc(100vh - 50px);
  .viewtop {
    overflow: auto;
	height: 100%;
  }
  .viewbottom {
    height: 68px;
    .btn {
      width: 6.9rem;
      margin: auto;
    }
  }
}
.item {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(225, 225, 225, 0.5);
  border-radius: 8px;
  width: 7.1rem;
  margin: 10px auto 0;
  box-sizing: border-box;
  padding: 15px;
  &.top {
    // line-height: 30px;
  }
  &.tbg {
    .ttr {
      overflow: hidden;
      padding: 5px 0;
    }
    .ttd1 {
      width: 5em;
      float: left;
      color: #5d6e7f;
      font-size: 14px;
      line-height: 26px;
      vertical-align: text-top;
    }
    .ttd2 {
      font-size: 13px;
      color: #272d3b;
      float: left;
      line-height: 26px;
      width: calc(100% - 5em - 12px);
    }
  }
  &.itemc {
    .titles {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #5d6e7f;
    }
    .imgs {
      img {
        width: 1.2rem;
        height: 1.2rem;
        margin: 5px;
        object-fit: contain;
      }
      .download-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #ccc;
        margin: 10px 0;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 8px;
        height: 80px;
        .file-name {
          span {
			  color: #00a8ff;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-decoration: underline;
            font-style: italic;
            white-space: pre-wrap;
          }
        }
      }
    }
  }
}

.userName {
  font-weight: 500;
  color: #272d3b;
  font-size: 18px;
}
.userInfo {
  font-size: 14px;
  color: #272d3b;
  margin-left: 10px;
}
</style>
