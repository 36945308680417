<template>
	<div class="page-wrapper">
		<!-- 视频会议 --弹出组件调用处 -->
		<!-- <span @click="recOpen"><slot></slot></span> -->
		  <!-- <van-popup v-model:show="isLoading.record" style="height: 100vh;" get-container="body" position="bottom">
		  	<div style="max-height: calc(100vh - 45px);overflow: auto;">
		  		<compRoom ref="compRoom" v-if="isLoading.record" :activeUser="activeUser"  :myUserId="userId"  @leave="leave"></compRoom>
		  	</div>
		  </van-popup> -->
		<div  class="gdtk" v-show="show" >
			<compRoom ref="compRoom" v-if="show" :activeUser="activeUser" :userObj="userObj" :myUserId="userId"  @leave="leave"></compRoom>
		</div>
	</div>
</template>

<script>
import compRoom from './comp-room.vue';
import {Popup} from 'vant';
export default {
	name: 'videoConferencing',
	components: {
		[Popup.name]:Popup,
		compRoom,
	},
	data() {
		return {
			isLoading: {
				record: false
			}
		};
	},
	props:["userId","activeUser",'show','userObj'],
	created() {
		// let options = {};
	},
	methods: {
		// 显示视频会议
		recOpen() {
			// if(!this.roomId||!this.userId){
			// 	alert("用户和房间号获取失败")
			// 	return;
			// }
			this.isLoading.record=true;
		},
		// 关闭回调
		handleClose(next){
			this.recClose()
			next();
		},
		// 关闭
		recClose() {
			// 退出房间 --关闭
			if(this.$refs.compRoom){
				this.$refs.compRoom.handleLeave();
			}else{
				this.$emit('update:show',false)
			}
		},
		// 视频离开 --回调
		leave() {
			// this.recClose()
			this.$emit('update:show',false)
		}
	},
	mounted() {}
};
</script>

<style lang="scss" scoped>
	.page-wrapper{
		z-index: 1000;
	}
	.gdtk{
		position: fixed;
		height: 100vh;
		width: 100%;
		overflow: hidden;
		top: 0;
		left: 0;
		background-color: #fff;
	}
</style>
